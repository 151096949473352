<template>
   <v-row justify="center">
    <v-col cols="12" sm="10" md="8" lg="6">
      <v-card ref="form">
        <v-card-title> Add New Medication </v-card-title>
        <v-card-text>
                  <v-text-field
                    v-model="name"
                    label="Medication Name"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="directive"
                    label="Directives"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="description"
                    label="Description"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="type"
                    label="Type"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="pharmacy"
                    label="Pharmacy"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="quantity.order"
                    label="Quantity Order"
                    type="number"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="quantity.receive"
                    label="Quantity Receive"
                    type="number"
                    required
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" class="mr-12">
                          <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="startDate"
                                label="Start Date"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="startDate" no-title scrollable
                              @input="menu1 = flase"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="4" class="ml-12 pl-2">
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="endDate"
                                label="End Date"  
                                prepend-icon="event"
                                readonly
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="endDate" no-title scrollable
                              @input="menu2 = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                  </v-row>
                  <v-btn
                    color="warning"
                    @click="register"
                  >
                    Submit
                  </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
   </v-row>
</template>

<script>
import {mapGetters, mapActions } from 'vuex'
import moment from 'moment';

export default {
    name:'registerMedication',
    data () {
      return {
        name: '',
        directive: '',
        description: '',
        quantity: {
          order: 0,
          receive: 0,
        },
        type: '',
        pharmacy: '',
        startDate: null,
        endDate: null,
        resident: '',
        user: '',
        menu1: false,
        menu2: false,
      }
    },
    computed: {
        ...mapGetters([
           'getLoggedUser'
        ]),
    },
     created() {
       this.getLoggedInUser();
    },
    methods: {
      ...mapActions([
          'register_medication',
          'getLoggedInUser',
      ]),
      register () {
        this.user = this.getLoggedUser.user.id,
        this.resident = this.$route.params.residentId;
        this.$data.endDate = this.formatDate(this.$data.endDate);
        this.$data.startDate = this.formatDate(this.$data.startDate);
        this.register_medication({
          ...this.$data
        })
        /* eslint-disable */
        .then(response => {
            this.$router.push('/medications')
        })
        .catch(error => {
            console.log(error)
        })
      },
      formatDate(date) {
        date = moment(date).format('DD-MM-YYYY HH:mm');
        return date
      }
    },
}
</script>

<style lang="stylus" scoped>

</style>